/* eslint-disable */
const config = {
  apiHost: "https://slidespielportalsapi.azurewebsites.net",
  // apiHost: "https://slidespielportalsapitest.azurewebsites.net",
  spmApiHost: "https://slidespielspeakersapi.azurewebsites.net",
  chatRealm: "https://chat.slidespiel.com",
  portalPrice: 0,
  portalPriceForMembers: 0,
  stripeKey: "pk_test_0hlApLH5MBAWQzgK4nfccP1H",
  url: "",
  env: "development",
  portalName: "clearwater-ce",
  title: "Clearwater Company Meeting",
  hasCme: false,
  questionsBeforeRecording: false,
  portals: [
    { year: 2020, id: "a271c1ac-f191-4299-b9f1-58c44a593e42" },
  ],
  defaultYear: 2020,
  // amplitudeKey: "5a691f5d28c551997691879d728c1da7",
  version: "0.2.1",
};

if (process.env.NODE_ENV === "production") {
  Object.assign(config, {
    apiHost: "https://slidespielportalsapi.azurewebsites.net",
    stripeKey: "pk_live_nJvbydZp1mBjTIkW4mzW33B5",
    env: "production",
    sentryDsn: "https://66ebec47e0014dccb50af5e51f5348eb@o361175.ingest.sentry.io/5264442",
  });
}


export default config;
